import { useCallback, useMemo } from 'react';
import { sprintf } from 'sprintf-js';

import { LANGUAGES } from 'utils/constants/general/languages';
import { createCookie } from 'utils/cookies';

import {
  PageFooterLanguageLinksDisabledText,
  PageFooterLanguageLinksLink,
} from './PageFooterLanguageLinksItem.styled';

type Props = {
  locale: UserLocale;
  localeUrl: string | undefined;
};

export function PageFooterLanguageLinksItem({ locale, localeUrl }: Props) {
  const select = useCallback(
    () => createCookie('_LOCALE_', locale, 1825),
    [locale],
  );

  const title = useMemo(() => {
    if (!localeUrl)
      return sprintf(
        getText('This page is currently not available in %(language)s'),
        { language: LANGUAGES[locale] },
      );

    return '';
  }, [localeUrl, locale]);

  const isSelected = locale !== CURRENT_LOCALE();

  if (isSelected && localeUrl) {
    return (
      <PageFooterLanguageLinksLink
        href={localeUrl || ''}
        data-qa-id={`locale-link-${locale}`}
        onClick={select}
      >
        {LANGUAGES[locale]}
      </PageFooterLanguageLinksLink>
    );
  }

  return (
    <PageFooterLanguageLinksDisabledText
      data-qa-id={`disabled-locale-link-${locale}`}
      title={title}
    >
      {LANGUAGES[locale]}
    </PageFooterLanguageLinksDisabledText>
  );
}
